import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Logo from "../../assets/images/logo-with-so.png";
import config from '../../config';
import "./header.scss";
function HeaderComponent() {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = React.useState<boolean>();
    const clickToggle = async () => {
        if(isOpen){
            setIsOpen(false);
        }else{
            setIsOpen(true);
        }
    }

    React.useEffect( () => {
    })
    function clickHome(){
        window.open(config.HOME_URL, '_blank');
    }

    return (
        <div className={isOpen ? 'container-fluid container-fluid-custom-open'  :'container-fluid container-fluid-custom'}>
            <Navbar collapseOnSelect expand='md' variant='dark' className={isOpen ? "header-container-control-active" : "header-container-control"}>
                <Navbar.Brand href="https://mondayclub.io"><img src={Logo} className="scoutout-logo"></img></Navbar.Brand>

                <Navbar.Toggle aria-controls='responsive-navbar-nav' onClick={clickToggle} />
                <Navbar.Collapse id='responsive-navbar-nav'>
                    <Nav >
                        <Nav.Link onClick={clickHome} className="my-auto" active>Home</Nav.Link>
                        <Nav.Link href="/" target="_blank" className="my-auto">Marketplace</Nav.Link>
                        <Nav.Link href='https://scoutout.io/scoutout-system/'  target="_blank" className="my-auto">ScoutOut Services</Nav.Link>
                        {/* {
                            account ?
                                <Nav.Link>
                                    <FlexWrapper display="flex" directionFlex="row">
                                        <img src={LogoutIcon} className="logout-img"></img> Logout
                                    </FlexWrapper>
                                </Nav.Link>
                                : null
                        } */}

                    </Nav>

                </Navbar.Collapse>

            </Navbar>
        </div>
    )

}

export default HeaderComponent;