import { FlexWrapper, GridWrapper } from "../../theme/theme";
import "./footer.scss"

import COMMU_DC_ACTIVE from "../../assets/images/commu-dc-active.png";
import COMMU_TW_ACTIVE from "../../assets/images/commu-tw-active.png";
import COMMU_MD_ACTIVE from "../../assets/images/commu-md-active.png";
import COMMU_GH_ACTIVE from "../../assets/images/commu-gh-active.png";
import COMMU_TG_ACTIVE from "../../assets/images/commu-tg-active.png";
import COMMU_FB_ACTIVE from "../../assets/images/commu-fb-active.png";
import Logo from "../../assets/images/logo.png";
import { useWindowWidth } from "@react-hook/window-size";
function FooterComponent() {
    const width = useWindowWidth();
    const isMobile: Boolean = width < 768 ? true : false
    const isTablet: Boolean = width < 992 && width >= 768 ? true : false

    function openDiscord() {
        window.open('https://discord.gg/zFxnfQ5a', '_blank');
    }
    function openTwitter() {
        window.open('https://twitter.com/MondayClubHQ', '_blank');
    }
    function openTelegram() {
        window.open('https://t.me/mondayclub', '_blank');
    }

    function openFacebook() {
        window.open('https://www.facebook.com/MondayClub.io/', '_blank');
    }

    function openTeam() {
        window.open('https://mondayclub.gitbook.io/monday-club/the-formal-stuff/team', '_blank');
    }

    function openAboutUs() {
        window.open('https://mondayclub.gitbook.io/monday-club/the-formal-stuff/team', '_blank');
    }

    function openGitbook() {
        window.open('https://mondayclub.gitbook.io/monday-club/', '_blank');
    }
    return (
        <GridWrapper columnTemplate={isMobile ? "100%" : isTablet ? "50% 50%" : "50% 50%"} className="footer-control">

            <FlexWrapper directionFlex="column">
                <img src={Logo} className="logo my-auto"  />




            </FlexWrapper>
            <FlexWrapper directionFlex="column" className="my-auto">

                <span className="follow-text mx-auto">FOLLOW US</span>
                <FlexWrapper directionFlex="row" className="contact-control mx-auto">

                    <img className="contact-item" src={COMMU_TW_ACTIVE} onClick={openTwitter}></img>
                    <img className="contact-item" src={COMMU_DC_ACTIVE} onClick={openDiscord}></img>
                    <img className="contact-item" src={COMMU_TG_ACTIVE} onClick={openTelegram}></img>
                    <img className="contact-item" src={COMMU_FB_ACTIVE} onClick={openFacebook}></img>
                    {/* <img className="contact-item" src={COMMU_DC_ACTIVE}></img>
                    <img className="contact-item" src={COMMU_DC_ACTIVE}></img>*/}
                </FlexWrapper>
                <GridWrapper columnTemplate={isMobile || isTablet ? "50% 50%" : "25% 25% 25% 25%"}>
                    <div className="footer-text-li"> <a href="mailto:mondayclubteam@gmail.com" className="footer-text-title">Contact Us</a></div>
                    <div className={isMobile || isTablet ? "footer-text-li-last" : "footer-text-li"}> <a href="" onClick={openAboutUs} className="footer-text-title">About us</a></div>
                    <div className="footer-text-li"> <a href="" onClick={openGitbook} className="footer-text-title">Gitbook</a></div>
                    <div className="footer-text-li-last"> <a href="" onClick={openTeam} className="footer-text-title">Join our team</a></div>
                </GridWrapper>
              
            </FlexWrapper>
        </GridWrapper>
    )

}

export default FooterComponent;