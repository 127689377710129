const GQCOLLECTION = {

    collection_name: "GQ APPAREL",
    collection_id : "0",
    traded : 0,
    players : 0,
    listed : 28,
    volumn : 0,
    floor : 0,
    collection_prefix: 'gq-collections',
    collection_item_format: "GQ SalaryMang",
    items : [
        {
            item_id : "1",
            item_name : "Cool Underwear Red Mask",
            item_code: "#0001",
            item_image : "1.png",
            item_price : "0.02"
        },
        {
            item_id : "2",
            item_name : "Giant Passion Fire",
            item_code: "#0002",
            item_image : "2.png",
            item_price : "0.02"
        },
        {
            item_id : "3",
            item_name : "Space Science with Mask",
            item_code: "#0003",
            item_image : "3.png",
            item_price : "0.02"
        },
        {
            item_id : "4",
            item_name : "Red Witch",
            item_code: "#0004",
            item_image : "4.png",
            item_price : "0.02"
        },
        {
            item_id : "5",
            item_name : "Aerobic Cool",
            item_code: "#0005",
            item_image : "5.png",
            item_price : "0.02"
        },
        {
            item_id : "6",
            item_name : "Ruby Smoke",
            item_code: "#GQ006",
            item_image : "6.png",
            item_price : "0.02"
        },
        {
            item_id : "7",
            item_name : "GQ To the Moon",
            item_code: "#0007",
            item_image : "7.png",
            item_price : "0.02"
        },
        {
            item_id : "8",
            item_name : "Tiger Monster",
            item_code: "#0008",
            item_image : "8.png",
            item_price : "0.02"
        },
        {
            item_id : "9",
            item_name : "GQ To the Moon",
            item_code: "#0009",
            item_image : "9.png",
            item_price : "0.02"
        },
        {
            item_id : "10",
            item_name : "Tiger Monster",
            item_code: "#0010",
            item_image : "10.png",
            item_price : "0.02"
        },
        {
            item_id : "11",
            item_name : "GQ To the Moon",
            item_code: "#0011",
            item_image : "11.png",
            item_price : "0.02"
        },
        {
            item_id : "12",
            item_name : "Tiger Monster",
            item_code: "#0012",
            item_image : "12.png",
            item_price : "0.02"
        },
        {
            item_id : "13",
            item_name : "GQ To the Moon",
            item_code: "#0013",
            item_image : "13.png",
            item_price : "0.02"
        },
        {
            item_id : "14",
            item_name : "Tiger Monster",
            item_code: "#0014",
            item_image : "14.png",
            item_price : "0.02"
        },
        {
            item_id : "15",
            item_name : "GQ To the Moon",
            item_code: "#0015",
            item_image : "15.png",
            item_price : "0.02"
        },
        {
            item_id : "16",
            item_name : "Tiger Monster",
            item_code: "#0016",
            item_image : "16.png",
            item_price : "0.02"
        },
        {
            item_id : "17",
            item_name : "GQ To the Moon",
            item_code: "#0017",
            item_image : "17.png",
            item_price : "0.02"
        },
        {
            item_id : "18",
            item_name : "Tiger Monster",
            item_code: "#0018",
            item_image : "18.png",
            item_price : "0.02"
        },
        {
            item_id : "19",
            item_name : "GQ To the Moon",
            item_code: "#0019",
            item_image : "19.png",
            item_price : "0.02"
        },
        {
            item_id : "20",
            item_name : "Tiger Monster",
            item_code: "#0020",
            item_image : "20.png",
            item_price : "0.02"
        },
        {
            item_id : "21",
            item_name : "GQ To the Moon",
            item_code: "#0021",
            item_image : "21.png",
            item_price : "0.02"
        },
        {
            item_id : "22",
            item_name : "Tiger Monster",
            item_code: "#0022",
            item_image : "22.png",
            item_price : "0.02"
        },
        {
            item_id : "23",
            item_name : "Tiger Monster",
            item_code: "#0023",
            item_image : "23.png",
            item_price : "0.02"
        },
        {
            item_id : "24",
            item_name : "Tiger Monster",
            item_code: "#0024",
            item_image : "24.png",
            item_price : "0.02"
        },
        {
            item_id : "25",
            item_name : "Tiger Monster",
            item_code: "#0025",
            item_image : "25.png",
            item_price : "0.02"
        },
        {
            item_id : "26",
            item_name : "Tiger Monster",
            item_code: "#0026",
            item_image : "26.png",
            item_price : "0.02"
        },
        {
            item_id : "27",
            item_name : "Tiger Monster",
            item_code: "#0027",
            item_image : "27.png",
            item_price : "0.02"
        },
        {
            item_id : "28",
            item_name : "Tiger Monster",
            item_code: "#0028",
            item_image : "28.png",
            item_price : "0.02"
        }
    ]
}


export default {
   
    ...GQCOLLECTION
};