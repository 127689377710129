import { useWindowWidth } from "@react-hook/window-size";
import { useParams } from "react-router-dom";
import gqCollection from "../../assets/json/gq-collection";
import config from "../../config";
import { FlexWrapper, GridWrapper } from "../../theme/theme";
import ItemsComponent from "../items/items";
import './collections.scss';



function CollectionsComponent() {
    let { id } = useParams();
    const width = useWindowWidth();
    const isMobile: Boolean = width < 768 ? true : false
    const isTablet: Boolean = width < 992 && width >= 768 ? true : false


    return (

        <FlexWrapper directionFlex="column" className="collection-control">
            <FlexWrapper directionFlex="column" className="container mx-auto">
                <h1 className="collection-name-header">GQ APPAREL</h1>
                <GridWrapper columnTemplate={isMobile ? "50% 50%" : isTablet ? "33% 33% 33%" : "20% 20% 20% 20% 20%"} className="collection-info-control">
                    <FlexWrapper directionFlex="column" className="mx-auto">
                        <span className="collection-title-info">{config.INFO_COLLECTION.TRADED}</span>
                        <span className="collection-value-info">{gqCollection.traded}</span>
                    </FlexWrapper>
                    <FlexWrapper directionFlex="column" className="mx-auto">
                        <span className="collection-title-info">{config.INFO_COLLECTION.PLAYERS}</span>
                        <span className="collection-value-info">{gqCollection.players}</span>
                    </FlexWrapper>
                    <FlexWrapper directionFlex="column" className="mx-auto">
                        <span className="collection-title-info">{config.INFO_COLLECTION.LISTED}</span>
                        <span className="collection-value-info">{gqCollection.listed}</span>
                    </FlexWrapper>
                    <FlexWrapper directionFlex="column" className="mx-auto">
                        <span className="collection-title-info">{config.INFO_COLLECTION.VOL}</span>
                        <span className="collection-value-info">{gqCollection.volumn}</span>
                    </FlexWrapper>
                    <FlexWrapper directionFlex="column" className="mx-auto">
                        <span className="collection-title-info">{config.INFO_COLLECTION.FLOOR}</span>
                        <span className="collection-value-info">{gqCollection.floor}</span>
                    </FlexWrapper>
                </GridWrapper>

                <span className="collection-desc-text">When clothes are functioning and fitting the way they should be, you’re not going to be thinking about them. We design highly functional, elevated basics that are so easy to wear you’re more focused on your day and not, say, a wrinkled shirt.</span>
            </FlexWrapper>
            <ItemsComponent collectionID="GQ"></ItemsComponent>
        </FlexWrapper>
    )
}

export default CollectionsComponent;