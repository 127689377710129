import React from 'react';
import logo from './logo.svg';
import './App.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import FooterComponent from './components/footer/footer';
import MainComponent from './components/main/main';
import HeaderComponent from './components/header/header';
import CollectionsComponent from './components/collections/collections';
import ItemsComponent from './components/items/items';
import ItemDetailComponent from './components/item-detail/item-detail';
function App() {
  return (
    <Router>
      <div className="body-main">
        <HeaderComponent></HeaderComponent>
        <Routes>
          <Route path='/' element={<MainComponent />} />
          <Route path='/collections/:id' element={<CollectionsComponent />}/>
          <Route path='/collections/:id/:itemid' element={<ItemDetailComponent />}/>
       

        </Routes>

        <FooterComponent></FooterComponent>
      </div>
      
    </Router>
  );
}

export default App;
