import { useWindowWidth } from "@react-hook/window-size";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import gqCollection from "../../assets/json/gq-collection";
import { FlexWrapper, GridWrapper } from "../../theme/theme";
import './item-detail.scss';


function ItemDetailComponent() {
    let { itemid } = useParams();
    const width = useWindowWidth();
    const [indexItem , setIndexItem] = React.useState(0);
    const isMobile: Boolean = width < 768 ? true : false
    const isTablet: Boolean = width < 992 && width >= 768 ? true : false

    React.useEffect(() => {
        console.log(itemid);

        gqCollection.items.forEach((element, i) => {
            if (element.item_id == itemid) {
                setIndexItem(i);
                console.log(indexItem)
            }
        });
    }, [])

    return (
        <FlexWrapper directionFlex="column" className="item-detail-control">
            <GridWrapper columnTemplate={isMobile ? "100%" : "50% 50%"} className="container item-grid-control mx-auto">
                <div className="item-detail-nft-img-hover-zoom">
                    <img src={"../../../assets/images/" + gqCollection.collection_prefix + "/" + gqCollection.items[indexItem].item_image} className="item-detail-nft-img"></img>
                </div>
                <FlexWrapper directionFlex="column" className="item-detail-info-control">
                    <span className="info-collection">
                        {gqCollection.collection_name}
                    </span>

                    <h1 className="info-header">
                        {gqCollection.collection_item_format}
                    </h1>
                    <div className="info-hashcode-control">
                    <span className="info-hashcode">
                    {gqCollection.items[indexItem].item_code}
                    </span>
                    </div>
                  
                    <span className="info-preserve">
                        Owner by <a href="">0x....a2fe</a>
                    </span>

                </FlexWrapper>
            </GridWrapper>


        </FlexWrapper>
    )
}
export default ItemDetailComponent