import { useWindowWidth } from "@react-hook/window-size";
import { FlexWrapper, GridWrapper } from "../../theme/theme";
import './main.scss';
import MOCKAVATAR from "../../assets/images/mock-avatar.png";


function MainComponent() {
    const width = useWindowWidth();
    const isMobile: Boolean = width < 768 ? true : false
    const isTablet: Boolean = width < 992 && width >= 768 ? true : false


    return (
        <FlexWrapper directionFlex="column">



            <FlexWrapper directionFlex="column" className="section-0-control">
                <FlexWrapper directionFlex="column"
                    className="text-control">
                    <span className="title-header">Marketplace</span>
                    <span className="title-header">Coming soon.</span>

                </FlexWrapper>

                <FlexWrapper directionFlex="column" className="section-1-control">

                    <GridWrapper columnTemplate="100%" className="container mx-auto my-auto">

                        <GridWrapper columnTemplate="33% 33% 33%" className=" grid-nft-control mx-auto" >

                            <FlexWrapper directionFlex="column" className="item-nft-control">
                                <img src={MOCKAVATAR} className="img-nft"></img>
                                <div className="img-cover"></div>
                            </FlexWrapper>

                            <FlexWrapper directionFlex="column" className="item-nft-control">
                                <img src={MOCKAVATAR} className="img-nft"></img>
                                <div className="img-cover"></div>
                            </FlexWrapper>

                            <FlexWrapper directionFlex="column" className="item-nft-control">
                                <img src={MOCKAVATAR} className="img-nft"></img>
                                <div className="img-cover"></div>
                            </FlexWrapper>
                            <FlexWrapper directionFlex="column" className="item-nft-control">
                                <img src={MOCKAVATAR} className="img-nft"></img>
                                <div className="img-cover"></div>
                            </FlexWrapper>

                            <FlexWrapper directionFlex="column" className="item-nft-control">
                                <img src={MOCKAVATAR} className="img-nft"></img>
                                <div className="img-cover"></div>
                            </FlexWrapper>

                            <FlexWrapper directionFlex="column" className="item-nft-control">
                                <img src={MOCKAVATAR} className="img-nft"></img>
                                <div className="img-cover"></div>
                            </FlexWrapper>
                            <FlexWrapper directionFlex="column" className="item-nft-control">
                                <img src={MOCKAVATAR} className="img-nft"></img>
                                <div className="img-cover"></div>
                            </FlexWrapper>

                            <FlexWrapper directionFlex="column" className="item-nft-control">
                                <img src={MOCKAVATAR} className="img-nft"></img>
                                <div className="img-cover"></div>
                            </FlexWrapper>

                            <FlexWrapper directionFlex="column" className="item-nft-control">
                                <img src={MOCKAVATAR} className="img-nft"></img>
                                <div className="img-cover"></div>
                            </FlexWrapper>

                        </GridWrapper>
                    </GridWrapper>

                </FlexWrapper>
            </FlexWrapper>


        </FlexWrapper>
    )
}



export default MainComponent;

