const dev = {
    GA4_ID : "",
    GA_ID : "",
    HOME_URL : "https://monday-club-test.web.app",
    INFO_COLLECTION : {
        TRADED : "TRADED",
        PLAYERS : "PLAYERS",
        LISTED : "LISTED",
        VOL : "VOL.(BNB)",
        FLOOR : "FLOOR.(BNB)"
    }
};


const prod = {
    GA4_ID : "",
    GA_ID : "",
    HOME_URL : "https://mondayclub.io",
    INFO_COLLECTION : {
        TRADED : "TRADED",
        PLAYERS : "PLAYERS",
        LISTED : "LISTED",
        VOL : "VOL.(BNB)",
        FLOOR : "FLOOR.(BNB)"
    }
};

const config = process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : dev;

export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
};