import { Button, Select } from "@chakra-ui/react";
import { useWindowWidth } from "@react-hook/window-size";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import gqCollection from "../../assets/json/gq-collection";
import { FlexWrapper, GridWrapper } from "../../theme/theme";
import './items.scss'


const ItemsComponent = ({ collectionID: string }: any) => {
    const navigate = useNavigate();
    const width = useWindowWidth();
    const isMobile: Boolean = width < 768 ? true : false
    const isTablet: Boolean = width < 992 && width >= 768 ? true : false

    React.useEffect(() => {

    }, [])
    const goToPosts = (id: string) => {
        navigate('/collections/' + gqCollection.collection_prefix + '/' + id + "/");
    }



    return (
        <FlexWrapper directionFlex="column" className="items-control">
            <GridWrapper columnTemplate={isMobile ? "100%" : "50% 50%"} className="container items-section-0-control mx-auto">
                <GridWrapper></GridWrapper>

                <GridWrapper columnTemplate="33% 33% 33%" className="items-filter-control">
                    <FlexWrapper directionFlex="row" className="items-dropdown-control" >
                        <Select placeholder='All' className="items-dropdown-format" color='white'>
                            <option value='all'>All</option>
                            <option value='fixed'>Fixed Price</option>
                            <option value='auction'>Auction</option>
                            <option value='offer'>With Buy Offer</option>
                        </Select>
                    </FlexWrapper>
                    <FlexWrapper directionFlex="row" className="items-dropdown-control" >
                        <Select placeholder='Newest' className="items-dropdown-format" color='white'>
                            <option value='newest'>Newest</option>
                            <option value='low'>Price: Low to High</option>
                            <option value='high'>Price: High to Low</option>
                            <option value='end_soon'>Ending soon</option>
                        </Select>
                    </FlexWrapper>
                    <Button className="items-button-filter">Filter</Button>
                </GridWrapper>
            </GridWrapper>


            <GridWrapper columnTemplate={isMobile ? "100%" : isTablet ? "50% 50%" : "25% 25% 25% 25%"} className="container items-grid-control mx-auto">
                {gqCollection.items.map((item, i) => {
                    return (
                        <FlexWrapper directionFlex="column" className="item-nft-info-control" onClick={() => goToPosts(item.item_id)} key={i}>
                            <div className="items-nft-img-hover-zoom">
                                <img src={"../../assets/images/" + gqCollection.collection_prefix + "/" + item.item_image} className="items-nft-img"></img>
                            </div>

                            <GridWrapper columnTemplate="50% 50%">
                                <FlexWrapper directionFlex="column">
                                    <span className="items-nft-name">{gqCollection.collection_item_format}</span>
                                    <span className="items-nft-name">{item.item_code}</span>
                                </FlexWrapper>

                              
                            </GridWrapper>
                        </FlexWrapper>

                    )
                })}
            </GridWrapper>


        </FlexWrapper>
    );
}

export default ItemsComponent