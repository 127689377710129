import styled from 'styled-components';

export const Button = styled.img<{ width?: string, height?: string, objectFit?: string, ml?: string, mr?: string, mt?: string, mb?: string }>`
width: ${({ width }) => width ?? 0};
height: ${({ height }) => height ?? 0};
object-fit: ${({ objectFit }) => objectFit ?? 0};
margin-right: ${({ mr }) => mr ?? 0};
margin-left: ${({ ml }) => ml ?? 0};
margin-top: ${({ mt }) => mt ?? 0};
margin-bottom: ${({ mb }) => mb ?? 0};
`

export const FlexWrapper = styled.div<{  ml?: string, mr?: string, mt?: string, mb?: string, display?: string, directionFlex?: string }>`
  display: flex;
  margin-right: ${({ mr }) => mr ?? 0};
  margin-left: ${({ ml }) => ml ?? 0};
  margin-top: ${({ mt }) => mt ?? 0};
  margin-bottom: ${({ mb }) => mb ?? 0};
  flex-direction: ${({ directionFlex }) => directionFlex +" !important" ?? 'none'};
`



// for wrapper react feather icons
export const GridWrapper = styled.div<{ columnTemplate?: string; contentAlign? :string, ml?: string, mr?: string, mt?: string, mb?: string, rowGap?: string, display?: string, directionFlex?: string }>`
  width: 100%;  
  display: grid;
  grid-template-columns: ${({ columnTemplate }) => columnTemplate ?? '100%'};
  margin-right: ${({ mr }) => mr ?? 0};
  margin-left: ${({ ml }) => ml ?? 0};
  margin-top: ${({ mt }) => mt ?? 0};
  margin-bottom: ${({ mb }) => mb ?? 0};
  row-gap: ${({ rowGap }) => rowGap ?? 0};
  justify-content: ${({ contentAlign }) => contentAlign ?? 0};
  flex-direction: ${({ directionFlex }) => directionFlex ?? 'none'};
`